import React from 'react'

export function useLoadingData<T>(args: { data: T, loading?: boolean, defaultData?: T | null }) {
  const { data, loading = false, defaultData = null } = args
  const [state, setState] = React.useState<T | null>(defaultData)

  React.useEffect(() => {
    if (loading) return

    if (Array.isArray(data) && Array.isArray(state)) {
      const isEqual = data.length === state.length && data.every((item, i) => item === state[i])
      if (!isEqual) {
        setState(data)
      }
    } else if (data !== state) {
      setState(data)
    }
  }, [data, loading, defaultData])

  return state
}
